import {OrganizationsStatusModel} from '@coveo/platform-client';
import {createSelector} from 'reselect';

import {Organization, OrganizationState} from '../store';

const getOrganization = (state: OrganizationState): Organization => state.organization || {};

const getOrganizationStatus = (state: OrganizationState): OrganizationsStatusModel | undefined =>
    state.organization?.organizationStatusModel;

const getOrganizationId = createSelector(getOrganization, (org: Organization): string => (org && org.id) || '');

export const OrganizationSelectors = {
    getOrganization,
    getOrganizationStatus,
    getOrganizationId,
};
