import {Config} from '@core/configuration';

const lastSeenOrganization = 'lastSeenOrganization';
const lastUsedRegion = 'lastUsedRegion';

export class OrganizationLocalStorage {
    static getOrganizationFromLocalStorage(): string {
        const isInLastUsedRegion = localStorage.getItem(lastUsedRegion) === Config.CloudPlatform.region;

        if (typeof Storage === 'undefined' || !isInLastUsedRegion) {
            return '';
        }

        return localStorage.getItem(lastSeenOrganization) ?? '';
    }

    static setOrganizationInLocalStorage(organizationId: string, region = Config.CloudPlatform.region) {
        localStorage.setItem(lastSeenOrganization, organizationId);
        localStorage.setItem(lastUsedRegion, region);
    }
}
